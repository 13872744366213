import { Button, Modal, Form, Header, Message } from "semantic-ui-react";
import axios from "axios";
import { useEffect, useState } from "react";
const Contact = (props) => {
  const { setModal } = props;
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [contactObj, setContactObj] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    company: "",
    message: "",
  });

  const handleChangeFieldString = (e, data) => {
    let key = data.id;
    let value = data.value;
    let errors = { ...errorFields };
    Object.assign(errors, { [key]: false });
    setErrorFields(errors);
    let obj = { ...contactObj };
    Object.assign(obj, { [key]: value });
    setContactObj(obj);
  };

  const handleSend = async () => {
    setSending(true);
    //validate data first
    const errors = { ...errorFields };
    if (!contactObj.firstName) {
      Object.assign(errors, { firstName: true });
    }
    if (!contactObj.lastName) {
      Object.assign(errors, { lastName: true });
    }
    if (!contactObj.email) {
      Object.assign(errors, { email: true });
    }
    if (!contactObj.message) {
      Object.assign(errors, { message: true });
    }
    setErrorFields(errors);
    if (errors.firstName || errors.lastName || errors.email || errors.message) {
      setSending(false);
      return;
    }

    const { data } = await axios.post(
      "https://tower.paperairmedia.com/api/contactUs",
      {
        firstName: contactObj.firstName,
        lastName: contactObj.lastName,
        email: contactObj.email,
        phone: contactObj.phone,
        message: contactObj.message,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (data && data.message === "Sent") {
      setSuccess(true);
      setSending(false);
    } else {
      // something went wrong, eventually need more error handling than this
      setSending(false);
    }
  };

  const handleDismiss = () => {
    setSuccess(false);
    setModal(false);
    setContactObj({});
  };

  return (
    <Modal open size="small">
      <Modal.Header style={{ backgroundColor: "#4682CC" }}>
        <Header inverted>
          Contact Us
          <Header.Subheader>
            Please fill out the form below to inquire about a collaboration, or
            simply say hello.
          </Header.Subheader>
        </Header>
      </Modal.Header>
      <Modal.Content style={{ backgroundColor: "#F1F1F1" }}>
        <Form size="large">
          <Form.Group widths={"equal"}>
            <Form.Input
              error={errorFields.firstName}
              required
              id="firstName"
              label="First Name"
              value={contactObj.firstName}
              placeholder="First Name"
              onChange={handleChangeFieldString}
            />
            <Form.Input
              error={errorFields.lastName}
              required
              id="lastName"
              label="Last Name"
              value={contactObj.lastName}
              placeholder="Last Name"
              onChange={handleChangeFieldString}
            />
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Input
              id="phone"
              label="Phone"
              value={contactObj.phone}
              placeholder="Phone"
              onChange={handleChangeFieldString}
            />
            <Form.Input
              error={errorFields.email}
              required
              id="email"
              label="Email"
              value={contactObj.email}
              placeholder="Email"
              onChange={handleChangeFieldString}
            />
          </Form.Group>
          <Form.TextArea
            error={errorFields.message}
            required
            id="message"
            label="Message"
            value={contactObj.message}
            placeholder="Enter your message..."
            onChange={handleChangeFieldString}
          />
          {(errorFields.firstName ||
            errorFields.lastName ||
            errorFields.email ||
            errorFields.message) && (
            <Message
              visible
              error
              content="Please fill out all required fields."
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button size="large" content="Cancel" onClick={() => setModal(false)} />
        <Button
          size="large"
          primary
          content="Send"
          onClick={handleSend}
          loading={sending}
          disabled={sending}
        />
      </Modal.Actions>
      {success && (
        <Modal open size="tiny">
          <Message
            positive
            content="Thank you! Your message has been sent to the PaperAirplane team."
            onDismiss={handleDismiss}
          />
        </Modal>
      )}
    </Modal>
  );
};
export default Contact;
